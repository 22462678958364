import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { ReservationExtra } from "../interfaces/bookingSummary";
import { useIsMobile } from "../../../hooks/hooks";
import { FHBasketContainer } from "./FHBasketContainer";
import { BookingInfo } from "./BookingInfo";
import { BasketSection, BasketSections } from "./BasketSection";
import { Extras } from "./Extras";
import { MobileBasketWrapper } from "./MobileBasketWrapper";
import { ContinueButton } from "./ContinueButton";
import { BasketItem } from "./BasketItem";
import { ImageLoader } from "../FHNextImage";

export type SpecificPayment = {
  title: string;
  subtitle?: string;
  value: number;
};

export type FHBasketProps = {
  balanceDueAmount?: number;
  balanceDueLabel?: string;
  basketTitle?: string;
  bedroomLabel?: string;
  bookingImageUrl?: string;
  bookingReference?: string;
  bookingReferenceLabel?: string;
  cabinSellingPrice?: number;
  cabinType?: string;
  cabinUpgrades?: ReservationExtra[];
  cabinUpgradesSectionLabel?: string;
  continueToNextStepCTALabel?: string;
  continueToNextStepCTAOnClick?: () => void;
  defaultExpandedSection?: BasketSections;
  depositAmount?: number;
  depositLabel?: string;
  depositOverrideLiableAmount?: number;
  depositTooltip?: string;
  endDate?: string;
  excludeCabinInBasketTotalItems?: boolean;
  extras?: ReservationExtra[];
  extrasSectionLabel?: string;
  includedLabel?: string;
  isFHBasketReadOnly?: boolean;
  isPetFriendlyCabin?: boolean;
  loading?: boolean;
  locationName?: string;
  noCabinUpgradesDescription?: JSX.Element;
  noCabinUpgradesLabel?: string;
  noExtrasDescription?: JSX.Element;
  noExtrasLabel?: string;
  numberOfBedrooms?: string;
  onRemoveExtra?: (
    cabinReservationExtraId: string,
    extraId: string,
    isSCB: boolean,
  ) => void;
  paymentAmount?: number;
  promoAmount?: number;
  promoLabel?: string;
  preDiscountCabinSellingPrice?: number;
  preDiscountTotalAmount?: number;
  showAsBasketIconInMobile?: boolean;
  specificPayment?: SpecificPayment;
  specificCabinLabel?: string;
  specificCabinNumber?: string;
  startDate?: string;
  totalAmount?: number;
  totalDiscountLabel?: string;
  totalPaymentsLabel?: string;
  totalPriceLabel?: string;
  balanceDueDateISO?: string | null;
  disableContinueCTA?: boolean;
  mobileBasketButtonLabel?: string;
  mobileDrawerButtonIcon?: JSX.Element;
  remainingDepositLabel?: string;
  dueOnLabel?: string;
  remainingDeposit?: number;
  remainingBalanceLabel?: string;
  remainingBalance?: number;
  remainingDepositDueDate?: string | null;
  remainingBalanceDueDate?: string | null;
  imageLoader?: ImageLoader;
  mobileBasketAddLabel?: boolean;
  applyUnderliningStylingCheckoutCTAs?: boolean;
  showMandatoryExtrasRequiredText?: boolean;
  hideBasketCount?: boolean;
  isPostBookingJourney?: boolean;
  largerTotalPrice?: boolean;
  requiredDescription?: string;
  hideFooter?: boolean;
  removeImagesExtrasCabin?: boolean;
  addExtrasInOneSection?: boolean;
};

const useStyles = (fitToViewPort: boolean) =>
  makeStyles((theme) => ({
    bookingInfo: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1, 0),
    },
    contentBody: {
      [theme.breakpoints.down("sm")]: {
        height: fitToViewPort ? "calc(100vh - 252px)" : undefined,
        overflowY: "auto",
      },
    },
    textAlign: { textAlign: "center" },
  }))();

export const FHBasket = ({
  balanceDueAmount = 0,
  balanceDueDateISO,
  balanceDueLabel = "Balance due",
  basketTitle = "Basket",
  bedroomLabel = "bedroom",
  bookingImageUrl,
  bookingReference,
  bookingReferenceLabel = "Ref:",
  cabinSellingPrice = 0,
  cabinType,
  cabinUpgrades,
  cabinUpgradesSectionLabel = "Cabin Upgrades",
  continueToNextStepCTALabel = "Continue",
  continueToNextStepCTAOnClick,
  defaultExpandedSection = BasketSections.none,
  depositAmount,
  depositLabel = "Deposit",
  depositOverrideLiableAmount = 0,
  depositTooltip,
  endDate,
  excludeCabinInBasketTotalItems = false,
  extras,
  extrasSectionLabel = "Extras & Experiences",
  includedLabel = "Included",
  isFHBasketReadOnly = false,
  isPetFriendlyCabin = false,
  loading = false,
  locationName,
  noCabinUpgradesDescription,
  noCabinUpgradesLabel = "No cabin upgrades",
  noExtrasDescription,
  noExtrasLabel = "No extras or experiences",
  numberOfBedrooms,
  onRemoveExtra,
  paymentAmount = 0,
  promoAmount = 0,
  promoLabel = "Promo",
  preDiscountCabinSellingPrice,
  preDiscountTotalAmount,
  showAsBasketIconInMobile = true,
  specificPayment,
  specificCabinLabel = "Cabin",
  specificCabinNumber,
  startDate,
  totalAmount = 0,
  totalDiscountLabel = "Promo/discount",
  totalPaymentsLabel = "Payments",
  totalPriceLabel = "Total",
  disableContinueCTA,
  mobileBasketButtonLabel,
  mobileDrawerButtonIcon,
  remainingDepositLabel = "Remaining deposit",
  dueOnLabel,
  remainingDeposit,
  remainingBalanceLabel = "Remaining balance",
  remainingBalance,
  remainingDepositDueDate,
  remainingBalanceDueDate,
  imageLoader,
  mobileBasketAddLabel,
  applyUnderliningStylingCheckoutCTAs,
  showMandatoryExtrasRequiredText,
  hideBasketCount = false,
  isPostBookingJourney,
  largerTotalPrice,
  requiredDescription = "* Please select all required fields to continue",
  hideFooter,
  removeImagesExtrasCabin,
  addExtrasInOneSection,
}: FHBasketProps) => {
  const isMobile = useIsMobile() && showAsBasketIconInMobile;
  const classes = useStyles(!useIsMobile() || isMobile);

  const [expandedSection, setExpandedSection] = useState<BasketSections>(
    defaultExpandedSection,
  );

  let cabinUpgradesCount = 0;
  cabinUpgrades?.map(
    (cabinUpgrade) =>
      (cabinUpgradesCount =
        cabinUpgradesCount +
        (cabinUpgrade?.reservationExtraOptions.length ?? 0)),
  );
  let extrasCount = 0;
  extras?.map(
    (extra) =>
      (extrasCount =
        extrasCount + (extra?.reservationExtraOptions?.length ?? 0)),
  );
  const totalExtras = cabinUpgradesCount + extrasCount;

  const renderExtras = ({
    extrasData,
    noDataCount,
    noLabel,
    noDescription,
    includeCabinLabel = false,
  }: {
    extrasData: ReservationExtra[] | undefined;
    noDataCount?: number;
    noLabel?: string;
    noDescription?: JSX.Element;
    includeCabinLabel?: boolean;
  }) => {
    if (!extrasData) return null;

    const hasExtras = (noDataCount ?? 0) > 0;

    return (
      <Extras
        extras={extrasData}
        includedLabel={includedLabel}
        loading={loading}
        noExtrasDescription={
          hasExtras ? (
            (noDescription ?? null)
          ) : (
            <p className={classes.textAlign}>
              Add them to your booking to see them here
            </p>
          )
        }
        noExtrasLabel={
          hasExtras
            ? (noLabel ?? "")
            : "No cabin upgrades, extras or experiences"
        }
        onRemoveExtra={
          !!onRemoveExtra && !isFHBasketReadOnly
            ? (cabinReservationExtraId, extraId, isSCB) =>
                onRemoveExtra(cabinReservationExtraId, extraId, isSCB)
            : undefined
        }
        promoLabel={promoLabel}
        specificCabinLabel={includeCabinLabel ? specificCabinLabel : undefined}
        specificCabinNumber={
          includeCabinLabel ? specificCabinNumber : undefined
        }
        removeImagesExtrasCabin={removeImagesExtrasCabin}
        addExtrasInOneSection={addExtrasInOneSection}
      />
    );
  };

  return (
    <MobileBasketWrapper
      basketTitle={basketTitle}
      basketSubtitle={
        !loading && !!bookingReference
          ? `${bookingReferenceLabel} ${bookingReference}`
          : undefined
      }
      isMobile={isMobile}
      totalItems={
        hideBasketCount
          ? 0
          : excludeCabinInBasketTotalItems
            ? totalExtras
            : totalExtras + 1
      } // single cabin pre booking journey
      mobileBasketButtonLabel={mobileBasketButtonLabel}
      mobileDrawerButtonIcon={mobileDrawerButtonIcon}
      mobileBasketAddLabel={mobileBasketAddLabel}
    >
      <FHBasketContainer
        hideFooter={hideFooter}
        balanceDueAmount={balanceDueAmount}
        balanceDueLabel={balanceDueLabel}
        balanceDueDateISO={balanceDueDateISO}
        depositLabel={depositLabel}
        depositAmount={depositAmount}
        depositOverrideLiableAmount={depositOverrideLiableAmount}
        depositTooltip={depositTooltip}
        isMobile={isMobile}
        loading={loading}
        paymentAmount={paymentAmount}
        paymentLabel={totalPaymentsLabel}
        preDiscountTotalAmount={
          !!preDiscountTotalAmount && preDiscountTotalAmount > totalAmount
            ? preDiscountTotalAmount
            : undefined
        }
        promoAmount={promoAmount}
        promoLabel={totalDiscountLabel}
        totalLabel={totalPriceLabel}
        totalAmount={totalAmount}
        requiredText={
          showMandatoryExtrasRequiredText ? requiredDescription : undefined
        }
        continueButton={
          !!continueToNextStepCTAOnClick ? (
            <ContinueButton
              onClick={continueToNextStepCTAOnClick}
              label={continueToNextStepCTALabel}
              loading={loading}
              disableContinueCTA={disableContinueCTA}
              applyUnderliningStylingCheckoutCTAs={
                applyUnderliningStylingCheckoutCTAs
              }
            />
          ) : undefined
        }
        remainingDepositLabel={remainingDepositLabel}
        dueOnLabel={dueOnLabel}
        remainingDeposit={remainingDeposit}
        remainingBalanceLabel={remainingBalanceLabel}
        remainingBalance={remainingBalance}
        remainingDepositDueDate={remainingDepositDueDate}
        remainingBalanceDueDate={remainingBalanceDueDate}
        isPostBookingJourney={isPostBookingJourney}
        largerTotalPrice={largerTotalPrice}
      >
        <div className={classes.contentBody}>
          <BookingInfo
            bedroomLabel={bedroomLabel}
            bookingImageUrl={bookingImageUrl}
            cabinSellingPrice={cabinSellingPrice}
            cabinType={cabinType}
            endDate={endDate}
            loading={loading}
            locationName={locationName}
            numberOfBedrooms={numberOfBedrooms}
            petFriendly={isPetFriendlyCabin}
            preDiscountCabinSellingPrice={
              !!preDiscountCabinSellingPrice &&
              preDiscountCabinSellingPrice > cabinSellingPrice
                ? preDiscountCabinSellingPrice
                : undefined
            }
            startDate={startDate}
            specificCabinNumber={specificCabinNumber}
            imageLoader={imageLoader}
            bookingReferenceLabel={bookingReferenceLabel}
            bookingReference={bookingReference || ""}
          />
          <>
            {!!specificPayment && !loading && (
              <BasketItem
                id="specificPayment"
                title={specificPayment.title}
                value={specificPayment.value}
              />
            )}
            {!!cabinUpgrades && !addExtrasInOneSection && (
              <BasketSection
                expanded={expandedSection === BasketSections.cabinUpgrades}
                fitToViewPort={isMobile}
                onCloseClick={() => setExpandedSection(BasketSections.none)}
                onExpandClick={() =>
                  setExpandedSection(BasketSections.cabinUpgrades)
                }
                title={cabinUpgradesSectionLabel}
              >
                {renderExtras({
                  extrasData: cabinUpgrades,
                  noLabel: noCabinUpgradesLabel,
                  noDescription: noCabinUpgradesDescription,
                  includeCabinLabel: true,
                })}
              </BasketSection>
            )}

            {!!extras && !addExtrasInOneSection && (
              <BasketSection
                expanded={expandedSection === BasketSections.extras}
                fitToViewPort={isMobile}
                onCloseClick={() => setExpandedSection(BasketSections.none)}
                onExpandClick={() => setExpandedSection(BasketSections.extras)}
                title={extrasSectionLabel}
              >
                {renderExtras({
                  extrasData: extras,
                  noLabel: noExtrasLabel,
                  noDescription: noExtrasDescription,
                })}
              </BasketSection>
            )}
            {/* For FR-822 */}
            {(!!extras || !!cabinUpgrades) && addExtrasInOneSection && (
              <BasketSection
                expanded={true}
                fitToViewPort={isMobile}
                onCloseClick={() => setExpandedSection(BasketSections.none)}
                onExpandClick={() => setExpandedSection(BasketSections.extras)}
                title={`${cabinUpgradesSectionLabel}, ${extrasSectionLabel}`}
                addExtrasInOneSection={addExtrasInOneSection}
              >
                {renderExtras({
                  extrasData: [...(cabinUpgrades || []), ...(extras || [])],
                  noDataCount: cabinUpgradesCount + extrasCount,
                  noLabel:
                    totalExtras > 0
                      ? ""
                      : "No cabin upgrades, extras or experiences",
                  noDescription:
                    totalExtras > 0 ? (
                      <></>
                    ) : (
                      <p className={classes.textAlign}>
                        Add them to your booking to see them here
                      </p>
                    ),
                  includeCabinLabel: true,
                })}
              </BasketSection>
            )}
          </>
        </div>
      </FHBasketContainer>
    </MobileBasketWrapper>
  );
};
