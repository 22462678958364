import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Skeleton } from "@mui/material";
import moment from "moment";
import { ReservationExtra } from "../interfaces/bookingSummary";
import { FHCurrency } from "../FHCurrency";
import { BasketItem } from "./BasketItem";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    height: "100%",
  },
  container: {
    height: "100%",
  },
  extraImage: {
    borderRadius: "50%",
    height: 50,
    width: 50,
  },
  extraOptionContainer: {
    padding: theme.spacing(0, 2),
    position: "relative",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "50%",
      height: 2,
      width: 10,
      marginLeft: -5,
      backgroundColor: theme.palette.primary.light,
      opacity: 0,
      transition: "all 0.5s",
    },
    "&:after": {
      top: "auto",
      bottom: 0,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light}0A`,
      "&:before, &:after": {
        left: 0,
        right: 0,
        width: "100%",
        marginLeft: 0,
        opacity: 1,
      },
    },
    "&:last-child": {
      "& $extraOptionItem": {
        borderBottom: "none",
      },
    },
  },
  extraOptionItem: {
    padding: theme.spacing(1.5, 0),
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  extraSmallText: {
    fontSize: "13px",
  },
  extraText: {
    fontSize: "15px",
  },
  importantSmallText: {
    fontSize: "13px",
    color: theme.palette.primary.main,
  },
  noExtras: {
    padding: theme.spacing(2),
  },
  noExtrasDescription: {
    marginTop: theme.spacing(-1.5),
  },
  noExtrasDescriptionExp: {
    marginTop: theme.spacing(0.8),
  },
  noExtrasLabel: {
    fontSize: 18,
    fontFamily: theme.typography.subtitle1.fontFamily,
    fontWeight: 600,
  },
  preDiscountSellingPrice: {
    display: "inline",
    color: theme.palette.grey[400],
    textDecoration: "line-through",
    marginRight: theme.spacing(0.5),
    fontSize: "13px",
  },
  sellingPrice: {
    fontSize: "13px",
    display: "inline",
  },
}));

type BasketExtrasProps = {
  extras: ReservationExtra[];
  includedLabel?: string;
  loading?: boolean;
  noExtrasLabel?: string;
  noExtrasDescription?: JSX.Element;
  onRemoveExtra?: (
    cabinReservationExtraId: string,
    extraId: string,
    isSCB: boolean,
  ) => void;
  promoLabel?: string;
  specificCabinLabel?: string;
  specificCabinNumber?: string;
  removeImagesExtrasCabin?: boolean;
  addExtrasInOneSection?: boolean;
};

export const Extras: React.FC<BasketExtrasProps> = ({
  extras,
  includedLabel = "Included",
  loading = false,
  noExtrasLabel = "No Extras",
  noExtrasDescription,
  onRemoveExtra,
  promoLabel = "Promo",
  specificCabinLabel = "Cabin",
  specificCabinNumber,
  removeImagesExtrasCabin,
  addExtrasInOneSection,
}: PropsWithChildren<BasketExtrasProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading ? (
        <Skeleton height={40} />
      ) : (
        <>
          {extras?.length > 0 ? (
            <>
              {extras.map((extra, i) => (
                <div key={`index-${i}`}>
                  {!!extra?.reservationExtraOptions &&
                    extra?.reservationExtraOptions?.map((option, i) => (
                      <div key={`index-${i}`}>
                        {!!option && (
                          <BasketItem
                            id={
                              (option?.cabinReservationExtraId || "") as string
                            }
                            title={extra.extraName}
                            subtitle={`${
                              !!extra.calendarDate
                                ? moment(extra.calendarDate).format("Do MMM YY")
                                : ""
                            }
                              ${
                                !!extra.calendarDate && !!extra.aMPM
                                  ? " - "
                                  : ""
                              }
                              ${!!extra.aMPM ? extra.aMPM : ""}`}
                            imageUrl={
                              !!extra.images && extra?.images?.length > 0
                                ? extra.images[0].url
                                : undefined
                            }
                            onRemove={
                              !!onRemoveExtra
                                ? () =>
                                    onRemoveExtra(
                                      option?.cabinReservationExtraId as string,
                                      option?.extraId as string,
                                      option?.isSCB as boolean,
                                    )
                                : undefined
                            }
                            description={
                              option.isSCB && !!specificCabinNumber ? (
                                <>
                                  {`${specificCabinLabel} #${specificCabinNumber}`}
                                </>
                              ) : (
                                <>
                                  {(option.quantity || 0) > 1 &&
                                    `${option.quantity} x `}
                                  {option.extraOptionName}
                                  {(option.quantity || 0) > 1 && (
                                    <>
                                      {" "}
                                      <FHCurrency>
                                        {option.extraOptionPrice || 0}
                                      </FHCurrency>
                                    </>
                                  )}
                                </>
                              )
                            }
                            preDiscountValue={option.preDiscountSellingPrice}
                            value={option.sellingPrice}
                            tag={
                              option.isIncludedInReservation
                                ? includedLabel
                                : (option.discountAmount ?? 0) > 0
                                  ? promoLabel
                                  : undefined
                            }
                            removeImagesExtrasCabin={removeImagesExtrasCabin}
                          />
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </>
          ) : (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.container}
            >
              <Grid item xs className={classes.noExtras}>
                <Typography align={"center"} className={classes.noExtrasLabel}>
                  {noExtrasLabel}
                </Typography>
                {!!noExtrasDescription && (
                  <div
                    className={classNames(classes.noExtrasDescription, {
                      [classes.noExtrasDescriptionExp]: addExtrasInOneSection,
                    })}
                  >
                    {noExtrasDescription}
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};
